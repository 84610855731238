.buttonContainer {
    display: block;
}

.buttonContainer:hover {
    text-decoration: none;
}

.iosButtonContainer {
    display: flex;
    justify-content: center;
}

.iosButtonContainer > a > img {
    width: 100%;
}

.mainContainer {
    font-family: var(--font-primary);
    border-radius: 6px;
    box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.5);
    position: relative;
    transform: skewX(0deg);
    padding: 1px;
    margin: 0 5px;
    overflow: hidden;
}

.content {
    border-radius: 6px;
    width: 100%;
    height: 100%;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    font-style: italic;
    letter-spacing: -0.32px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content p {
    margin: 0;
    transform: skewX(7deg);
}

.arrow-3 {
    display: flex;
}

.arrow-3-container span {
    height: 4px;
    width: 24px;
    display: block;
    overflow: hidden;
    background-color: #ffffff;
}

.shimmer {
    width: 100%;
    background-size: 1rem 1rem;
    animation-duration: 6s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmerAnimation;
    animation-timing-function: linear;
    background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.8),
            rgba(255, 255, 255, 0)
        )
        no-repeat 0 0;
    position: absolute;
    top: 1px;
    bottom: 1px;
    left: 1px;
    right: 1px;
    border-radius: 6px;
}

@keyframes shimmerAnimation {
    0% {
        background-position: -1200px 0;
    }
    100% {
        background-position: 1200px 0;
    }

}

@media screen and (max-width: 767px) {
    .fixedDownloadButtonContainer {
        display: block;
    }

    .iosButtonImage_FB {
        height: 42px;
    }
}