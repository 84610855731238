.buttonContainer {
    display: none;
}

.buttonContainer:hover {
    text-decoration: none;
}

.iosButtonContainer {
    display: flex;
    justify-content: center;
}

.iosButtonContainer>a>img {
    width: 100%;
}

.iconView {
    position: relative;
    width: 24px;
    height: 27px;
}

.mainContainer {
    font-family: var(--font-primary);
    border-radius: 6px;
    box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.5);
    position: relative;
    padding: 1px;
    overflow: hidden;
}

.animationContainer {
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: scale;
    animation-timing-function: ease-in-out;
    z-index: 1;
}

.content {
    border-radius: 6px;
    padding: 6px;
    min-width: clamp(100px, 36.4vw, 700px);
    width: 100%;
    height: 100%;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    letter-spacing: -0.32px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.content p {
    margin: 0;
}

.arrow3 {
    display: flex;
    ;
}

.arrow3container span {
    height: 4px;
    width: 24px;
    display: block;
    overflow: hidden;
    background-color: #ffffff;
}

.svg {
    transition: transform 500ms;
    animation: rotate 300ms linear 300ms 1 forwards;
}

.animContainer {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
}

.container1 {
    animation-duration: 3s;
    animation-fill-mode: initial;
    animation-iteration-count: infinite;
    animation-name: scaleBorder1;
    animation-timing-function: ease-in;
    border: 1.7px solid #A5F815;
}

.container2 {
    animation-duration: 3s;
    animation-fill-mode: initial;
    animation-iteration-count: infinite;
    animation-name: scaleBorder2;
    animation-timing-function: ease-in;
    border: 1.2px solid #A5F815;
}

.container3 {
    animation-duration: 3s;
    animation-fill-mode: initial;
    animation-iteration-count: infinite;
    animation-name: scaleBorder3;
    animation-timing-function: ease-in;
    border: 0.7px solid #A5F815;
}

@keyframes scaleBorder1 {
    0% {
        transform: scale(1);
        opacity: 0;
    }

    30% {
        transform: scale(1);
        opacity: 0;
    }

    65% {
        transform: scale(1.08);
        opacity: 1;
    }

    89% {
        transform: scale(1.08);
        opacity: 1;
    }

    97% {
        filter: blur(0.8px);
        opacity: 0;
        transform: scaleX(1.07) scaleY(1.4);

    }
}

@keyframes scaleBorder2 {
    0% {
        transform: scale(1);
        opacity: 0;
    }

    30% {
        transform: scale(1);
        opacity: 0;
    }

    65% {
        transform: scale(1.08);
        opacity: 1;
    }

    89% {
        transform: scale(1.08);
        opacity: 1;
    }

    97% {
        filter: blur(0.5px);
        opacity: 0;
        transform: scaleX(1.11) scaleY(1.7);
    }
}

@keyframes scaleBorder3 {
    0% {
        transform: scale(1);
        opacity: 0;
    }

    30% {
        transform: scale(1);
        opacity: 0;
    }

    65% {
        transform: scale(1.08);
        opacity: 1;
    }

    89% {
        transform: scale(1.08);
        opacity: 1;
    }

    97% {
        filter: blur(0.3px);
        opacity: 0;
        transform: scaleX(1.14) scaleY(2);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(-270deg);
    }
}

@keyframes scale {
    0% {
        transform: scale(1);
    }

    30% {
        transform: scale(1);
    }

    65% {
        transform: scale(1.08);
    }

    89% {
        transform: scale(1.08);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes ellipsisAnimation {
    0% {
        clip-path: inset(0 8.5% 0 0);
    }

    25% {
        clip-path: inset(0 6% 0 0);
    }

    50% {
        clip-path: inset(0 3% 0 0);
    }

    75% {
        clip-path: inset(0 0 0 0);
    }
}


@-webkit-keyframes shimmerAnimation {
    0% {
        transform: translate(-1000px);
    }

    100% {
        transform: translate(1000px);
    }
}

@keyframes shimmerAnimation {
    0% {
        transform: translate(-1000px);
    }

    100% {
        transform: translate(1000px);
    }
}

@-webkit-keyframes placeholderShimmer2 {
    0% {
        background-position: -10rem 0;
    }
}

@keyframes placeholderShimmer2 {
    0% {
        background-position: -10rem 0;
    }
}

@media screen and (max-width: 767px) {
    .buttonContainer {
        display: block;
        padding: 0px 10px;
    }

    .fixedDownloadButtonContainer {
        display: block;
    }

    .shimmer {
        width: 30%;
        background-size: 1rem 1rem;
        animation-duration: 2s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: shimmerAnimation;
        animation-timing-function: linear;
        background: linear-gradient(to right,
                rgba(255, 255, 255, 0),
                rgba(255, 255, 255, 0.5),
                rgba(255, 255, 255, 0)) no-repeat 0 0;
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: 1px;
        right: 1px;
        border-radius: 6px;
    }

    .shimmer2 {
        width: 100%;
        background-size: 10rem 1rem;
        animation-name: placeholderShimmer2;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        background-image: linear-gradient(90deg,
                rgba(255, 255, 255, 0.1) 20%,
                transparent 20%,
                transparent 50%,
                rgba(255, 255, 255, 0.1) 50%,
                rgba(255, 255, 255, 0.1) 70%,
                transparent 70%,
                transparent);
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: 1px;
        right: 1px;
        border-radius: 6px;
    }

}

.mainContainer.storyMainContainer {
    padding: 2px;
}

.downloadButtonTextStory {
    font-size: clamp(19px, 3.5vw, 85px);
}