.seasonToggleParentContainer {
  position: relative;
}

.toggleButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 5.8px;
  box-shadow: 0 5.8px 10.2px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px #fff;
  padding: 5px 0px;
  margin-right: 10px;
  max-width: clamp(100px, 7.82vw, 150px);
  min-width: clamp(100px, 7.82vw, 150px);
  position: relative;
  z-index: 2;
  background: #000;
}

.toggleButtonText {
  font-size: clamp(12px, 1.04vw, 20px);
  font-weight: 600;
  font-style: italic;
  letter-spacing: -0.28px;
  text-align: center;
  color: #fff;
  margin: 0;
  margin-right: 12px;
}

.toggleContainer {
  position: absolute;
  z-index: 1;
  top: 20px;
  background-color: #000;
  border: 1px solid #fff;
  border-radius: 5.8px;
  max-width: clamp(100px, 7.82vw, 150px);
  min-width: clamp(100px, 7.82vw, 150px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  padding: 15px 14px 0px;
}

.seasonItem {
  text-decoration: none;
  color: inherit;
  font-size: clamp(12px, 1.04vw, 20px);
  font-weight: 600;
  font-style: italic;
  letter-spacing: -0.28px;
  padding: clamp(6px, 0.6vw, 12px) 0;
  border-top: 1px solid #fff;
}

.seasonItem:hover {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
