.drawer {
    position: fixed;
    z-index: 999;
    height: 100%;
    right: 0;
    overflow: auto;
    transition: .3s;
}

.customScrollbar{
    padding:1px
}

.customScrollbar::-webkit-scrollbar {
    width: 6px;
}

.customScrollbar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
}

.customScrollbar::-webkit-scrollbar-thumb {
    background: rgba(255,255,255,1); 
}

.subLinksContainer {
    display: flex;
    margin-left: 30px;
    margin-bottom: 10px;
    flex-wrap: wrap;
}

.rotateArrow {
    transition: transform 0.4s ease;
  }
  
.rotateArrow.rotate {
transform: rotate(180deg);
}

.clickOutside {
    position: relative;
    font-family: var(--font-primary);
    /* font-size: 18px; */
    font-size: clamp(12px, 1vw, 20px);

    font-weight: 500;
    color: #d8d8d8;
    flex: 1 1 50%;
    text-decoration: none;
    padding: 2px 8px;
    padding-left:14px;
    margin: 0;
}

.clickInside {
    position: relative;
    font-family: var(--font-primary);
    /* font-size: 18px; */
    font-size: clamp(12px, 1vw, 20px);
    font-weight: 500;
    color: #d8d8d8;
    flex: 1 1 50%;
    text-decoration: none;
    padding: 2px 8px;
    padding-left:14px;
    margin: 0;
}

.clickOutside:hover::after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    margin-right: 20px;
    transform-origin: bottom left;
    transform: skew(-30deg, 0deg);
    z-index: -1;
    background: linear-gradient(to bottom, #b1fd2b, #31eb6f);
}

.clickInside:hover::after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: -9px;
    margin-right: 20px;
    transform-origin: center;
    transform: skew(-10rad, 0deg);
    z-index: -1;
    background: linear-gradient(to bottom, #b1fd2b, #31eb6f);
}

.clickInside:hover > a,
.clickOutside:hover,
.headingLinkContainer:hover > a,
p.navItemClickable:hover {
    color: #000 !important;
    opacity: 1 !important;
}

p.navHeadingClickable:hover {
    color: #000 !important;
    opacity: 1 !important;
}

.menuContainer {
    flex-direction: column;
    align-items: flex-start;
    padding:10px 0px
}

.drawer > div {
    background: #1e092d;
    height: 100%;
    margin-left: 70%;
    opacity: 0.99;
    overflow: auto;
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0;
    margin-top: 50px;
}

.heading{
    font-family: var(--font-primary);
    font-size: clamp(20px, 1.5vw, 32px);

    /* font-size: 220px; */
    font-weight: 900;
    color: #fff;
    text-transform: uppercase;
    transition: .3s;
    text-decoration: none;
    width: 100%;
    margin: 0;
    padding:0px;
    padding-left: 20px
}

.navItem {
    font-family: var(--font-primary);
    font-size: clamp(14px, 1vw, 24px);
    font-weight: 800;
    color: #fff;
    opacity: 0.5;
    text-transform: uppercase;
    transition: .3s;
    text-decoration: none;
    width: 100%;
    margin: 0;
    padding-left: 14px;
}

.navItem:hover {
    cursor: pointer;
}

.clickableArrow{
    
}

.clickableArrow:hover{
    cursor:pointer;
}

.navHeadingClickable {
    font-family: var(--font-primary);
    font-size: clamp(20px, 1.5vw, 32px);
    font-weight: 900;
    color: #fff;
    text-transform: uppercase;
    transition: .3s;
    text-decoration: none;
    width: 100%;
    margin: 0;
    padding:0px;
    padding-left: 10px
}

.mainHeadding{
    opacity:1;
}

.navHeadingClickable:hover::after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: -10px;
    transform-origin: bottom left;
    transform: skew(-10rad, 0deg);
    z-index: -1;
    background: linear-gradient(to bottom, #b1fd2b, #31eb6f);
}

.imgContainer1 {
    height: 25px;
    width: 20px;
    position: relative;
}
.subHeadingImgContainer{
    height: 20px;
    width: 15px;
    position: relative;
}

.gameRow {
    width: 100%;
    padding: 5px 10px;
    align-items: center;
}

.imgCol {
    padding-bottom: 2px;
    max-width: 20px;
}

.headingLinkContainer {
    max-width: 450px;
    width: 100%;
    position: relative;
    padding: 0;
    margin: 2px 15px;
}

.subHeadingBorder {
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.2);
}

@media screen and (max-width: 1200px) {
    .drawer > div {
        margin-left: 65%;
    }
}

@media screen and (max-width: 820px) {
    .drawer > div {
        margin-left: 50%;
    }

    .clickInside {
        padding: 2px 8px;
        font-size: clamp(10px, 1vw, 55px);
    }

    .clickOutside {
        font-size: clamp(10px, 1vw, 55px);
    }
    
    .navbar {
        padding: 0;
    }
    

    /* .navItem {
        font-size: 16px;
    } */
    /* .navHeadingClickable{
        font-size: clamp(20px, 5vw, 32px);
    } */

    /* .navItemClickable {
        font-size: 19px;
    } */
    .subLinksContainer {
        margin-bottom: 5px;
    }
}
@media screen and (max-width: 576px) {
    .drawer > div {
        margin-left: 30%;
    }
    .heading{
        font-size: clamp(12px,4vw,20px)
    }
    .navHeadingClickable{
        font-size: clamp(12px,4vw,20px)
    }
    .navItem{
        font-size: clamp(10px, 2.5vw, 18px);
    }
}
