.header {
    background-color: rgba(0, 0, 0, 0.9);
    position: fixed;
    top: 0;
    width: 100%;
    height: 51px;
    z-index: 1000;
    font-family: var(--font-primary);
    display: flex;
    align-items: center;
}
.season1{
    background-color: transparent;
    padding: clamp(7px, 0.4vw, 6px) clamp(10px, 1vw, 20px);
    border-radius: 5px;
    border:1px solid #fff;
    outline:none;
    margin-right: 10px;
    color:#fff;
    font-size: clamp(8px, 0.9vw, 18px);
    font-family: var(--font-primary);
    font-weight: 600;
    font-style: italic;
}
.season1:hover{
    background-color: #fff;
    color:#000;
}
.nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.logoContainer {
    display: flex;
    align-items: center;
}

.logo {
    position: relative;
    width: clamp(93px, 7.8vw, 150px);
    height: 35px;
}

.countryDropDownContainer {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    margin-left: 20px;
    min-width: fit-content;
    padding: 3px 12px 5px 14px;
    border-radius: 6px;
    border: solid 1px rgba(255, 255, 255, 0.4);
    background-color: rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

.countryOptionDropDownContainer {
    position: absolute;
    border-radius: 6px;
    border: solid 1px rgba(255, 255, 255, 0.4);
    background-color: rgba(0, 0, 0, 0.3);
    top: 50px;
    left: 22.5%;
    cursor: pointer;
    overflow: hidden;
}

.countryOptionDropDownContainer :hover {
    background-color: rgba(255, 255, 255, 0.15);
}

.countryDropDownContainer2 {
    display: flex;
    gap: 10px;
    padding: 3px 32px 5px 23px;
    background-color: rgba(0, 0, 0, 0.8);
    border: solid 1px rgba(255, 255, 255, 0.2);
}

.countryDropDownContainer2 :hover {
    background-color: transparent;
}

.flagImg {
    position: relative;
    width: 24px;
}

.arrowImg {
    position: relative;
    width: 24px;
    min-height: 24px;
}

.countryName {
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: -0.38px;
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
}

.countryName:hover {
    background-color: transparent;
}

.countryName1 {
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: -0.38px;
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
}

.countryName1:hover {
    background-color: transparent;
}

.nav a {
    text-decoration: none;
}

.navItemsContainer {
    display: flex;
    align-items: center !important;
}

.navItems > ul {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    align-items: center;
}

.navItems > ul > li > a, .navItems span {
    font-size: clamp(14px, 0.9vw, 18px);
    text-transform: uppercase;
    transition: .3s;
    display: block;
    padding: .3rem 1rem;
    font-weight: 600;
    letter-spacing: -0.26px;
    color: #fff;
}

.greenBackground {
    position: relative;
}

.greenBackground:hover::after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    right: 10px;
    transform-origin: bottom left;
    transform: skew(-30deg, 0deg);
    z-index: -1;
    background: linear-gradient(to bottom, #b1fd2b, #31eb6f);
}

.greenBackground:hover span {
    color: black
}

.greenBackground > a {
    color: white;
}

.headerButtonContainer {
    display: none;
}

.dropbtn {
    font-size: clamp(15px, 0.9vw, 18px);
    text-transform: uppercase;
    transition: .3s;
    display: block;
    padding: .3rem 1rem;
    font-weight: 600;
    letter-spacing: -0.26px;
    color: #fff;
    cursor: pointer;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdownContent {
    font-weight: 500;
    font-size: 12px;
    display: none;
    border-radius: 2px;
    position: absolute;
    right: 0;
    background-color: black;
    min-width: 120px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdownContent a {
    color: white;
    padding: 10px;
    text-decoration: none;
    display: block;
}

.dropdownContent a:hover {
    background: linear-gradient(to bottom, #b1fd2b, #31eb6f);
    border-radius: 2px;
    color: black;
}

.dropdown:hover .dropdownContent {
    display: block;
}

.dropdown:hover::after .dropbtn {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    top: 0;
    right: 10px;
    transform-origin: bottom left;
    transform: skew(-30deg, 0deg);
    z-index: -1;
    background: linear-gradient(to bottom, #b1fd2b, #31eb6f);
}

.languageToggle {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 1024px) {
    .drawer > div {
        margin-left: 50%;
    }
}

@media screen and (max-width: 767px) {

    .countryOptionDropDownContainer {
        left: 30%;
    }

    .countryName {
        display: none;
    }
}

@media screen and (max-width: 576px) {
    .drawer > div {
        margin-left: 40%;
    }

    .navItems > ul > li > a, .navItems span {
        padding: .3rem .25rem;
        font-weight: 500;
    }

    .headerButtonContainer {
        display: block
    }

    .languagetoggle {
        width: 22px;
        height: 22px;
    }
}

@media screen and (max-width: 400px) {
    .countryDropDownContainer {
        margin-left: 8px;
        padding-left: 6px;
        padding-right: 6px;
    }
}